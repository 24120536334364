import { Component, EventEmitter, Output, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { LetterAdditionsComponent } from "./letter-additions/letter-additions.component";
import { FlowStepsController, StepSaveResult } from "../../../../core/controllers/flow-steps.controller";
import { FlowStep4 } from "../../../../core/classes/flow-steps/flow-step4";
import { DocumentPreviewComponent } from "./document-preview/document-preview.component";
import { FlowService } from "../../../../core/lib/flow.service";
import { GeneralService } from "../../../../core/lib/general.service";

@Component({
  selector: "app-step4",
  templateUrl: "./step4.component.html",
  styleUrls: ["./step4.component.scss"],
  providers: [FlowStepsController]
})
export class Step4Component {

  @ViewChild("additions") additions!: LetterAdditionsComponent;
  @ViewChild("preview") preview!: DocumentPreviewComponent;

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onSuccess: EventEmitter<StepSaveResult> = new EventEmitter<StepSaveResult>();

  form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private _controller: FlowStepsController,
    private flowService: FlowService,
    private general: GeneralService
  ) {

    this.form = this.fb.group({});

  }

  ngAfterViewInit() {

    this.form.addControl("additions", this.additions!.form);
    this.additions.form.setParent(this.form);

  }

  /**
   * The function fetches the data from the server and sets the preview
   */
  fetch() {

    setTimeout(() => this.preview.setup(this.flowService.activeFlow));

  }

  /**
   * If the form is valid, then create a new FlowStep4 object, and pass it to the controller
   */
  submit() {

    this.form.markAllAsTouched();

    if (this.form.valid) {

      let parameter: FlowStep4 = new FlowStep4(this.additions.form.value.name);
      parameter.setCostCenter(this.additions.form.value.CostCenter);
      this._controller
        .step4Save(parameter)
        .then((result: StepSaveResult) => this.onSuccess.emit(result));

    } else {

      this.general.scrollToFirstInvalidControl();

    }

  }

}
