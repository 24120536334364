import { FlowStep } from "./flow-step";

export class FlowStep4 extends FlowStep {

  CostCenter : string | null = null;

  constructor(public FlowDescription: string) {
    super();
  }

  public setCostCenter(value: string | null) {
    
    this.CostCenter = value;
    return this;

  }

}
