import { Component, Inject, ViewChild } from "@angular/core";
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from "@angular/material/legacy-dialog";
import {
  SmartDeliveryConfigurationRow, SmartDeliveryConfigurationRowFile, SmartDeliveryConfigurationRowFileParameter
} from "../../../../../core/classes/smart-delivery-configuration-save.parameter";
import { Table } from "primeng/table";
import {
  SmartDeliveryActivationCondition2LabelMapping, SmartDeliveryActivationConditionOperator
} from "../../../../../core/enums/smart-delivery-activation-condition-operator";
import { SelectItem } from "primeng/api";
import {
  SmartDeliveryRuleRowActivationCondition
} from "../../../../../core/classes/smart-delivery-rule-row-activation-condition";
import { CtFileUploaderComponent, CtFileUploaderConfiguration, SnackbarService } from "@ctsolution/ct-framework";
import {
  SmartDeliveryConfigurationController
} from "../../../../../core/controllers/smart-delivery-configuration.controller";
import { CtWebapiGenericResponse } from "@ctsolution/ct-webapi/core/response.interface";

@Component({
  selector: "app-smart-delivery-rule-conditions",
  templateUrl: "./smart-delivery-rule-conditions.component.html",
  providers: [SmartDeliveryConfigurationController],
  styles: [`::ng-deep .row-attachment-file-uploader-container {
    .ct-file-uploader-panel .ct-file-uploader-cntr {
      padding: 15px 10px !important;
    }

    .mat-card {
      margin: 0px;
      margin-bottom: 20px;

      .mat-card-subtitle {
        margin-bottom: 0px
      }

      p {
        margin: 0
      }
    }
  }`]
})
export class SmartDeliveryRuleConditionsComponent {

  @ViewChild(Table) private dataTable: Table | null = null;
  @ViewChild("Uploader") fileUploader!: CtFileUploaderComponent;
  configuration: CtFileUploaderConfiguration = CtFileUploaderConfiguration
    .create()
    .setTitle(null)
    .setLimit(1)
    .setAcceptedFormats([
      "application/pdf",
      ".html"
    ]);
  activationConditionOptions: SelectItem[] = [];
  activationConditions: Array<SmartDeliveryRuleRowActivationCondition> = [];

  viewModel = {

    fileUploadEnabled: false,
    privateFile: false

  };

  constructor(
    private dialogRef: MatLegacyDialogRef<SmartDeliveryRuleConditionsComponent, SmartDeliveryConfigurationRow>,
    @Inject(MAT_LEGACY_DIALOG_DATA) private data: SmartDeliveryConfigurationRow | undefined,
    private smartDeliveryConfigurationController: SmartDeliveryConfigurationController,
    private snackbar: SnackbarService
  ) {

    this.activationConditions = (this.data?.ActivationConditions ?? []).map(condition => SmartDeliveryRuleRowActivationCondition.create(condition));
    this.activationConditionOptions = this.getActivationConditionOptions();

    this.viewModel.fileUploadEnabled = !!this.data?.Oid;

  }

  ngAfterViewInit() {

    this.setup();

  }

  setup() {

    const data = this.dataTable?.value ?? [];

    if (data.length) {

      // Impostare il flag di editing per ogni riga
      data.forEach(row => this.dataTable?.initRowEdit(row));

    }

    if (this.viewModel.fileUploadEnabled && (this.data?.Files ?? []).length) {

      const file = (this.data?.Files ?? [])[0];

      if (file) this.setupAttachment(file);

    }

  }

  setupAttachment(file: SmartDeliveryConfigurationRowFile) {

    this.fileUploader?.prepareFilesList([<File>{ name: file.Name }]);
    this.viewModel.privateFile = file.Private;

  }

  deleteAttachment(event: File) {

    const file: SmartDeliveryConfigurationRowFile | undefined = (this.data?.Files ?? []).find((f) => f.Name === event.name);

    if (file) {

      this.smartDeliveryConfigurationController
        .deleteRowFile(<SmartDeliveryConfigurationRowFileParameter>{
          SmartDeliveryConfigurationRowOid: this.data?.Oid!,
          FileOid: file.Oid
        })
        .subscribe(() => {

          this.fileUploader?.reset();
          this.data!.Files = [];

        });

    }

  }

  getActivationConditionOptions(): SelectItem[] {

    return Object.keys(SmartDeliveryActivationConditionOperator)
      .filter(key => !isNaN(Number((<any>SmartDeliveryActivationConditionOperator)[key]))).map(key => {

        const operator = Number((<any>SmartDeliveryActivationConditionOperator)[key]);
        return {
          label: (<any>SmartDeliveryActivationCondition2LabelMapping)[operator],
          value: operator
        };
      });

  }

  newCondition() {

    if (!this.activationConditions) {

      this.activationConditions = new Array<SmartDeliveryRuleRowActivationCondition>();

    }

    const obj = SmartDeliveryRuleRowActivationCondition
      .create();

    this.activationConditions.push(obj);

    setTimeout(() => this.setup());

  }

  deleteRow(index: number) {

    (this.activationConditions ?? []).splice(index, 1);

  }

  cancel() {

    this.dialogRef
      .close();

  }

  async sendFile() {

    const files = this.fileUploader?.configuration?.control?.value ?? [];
    const file = files[0] ?? null;

    if (!file) return Promise.reject("No file to upload");

    if (!file.type) return Promise.resolve();

    const parameter = SmartDeliveryConfigurationRowFileParameter.create(this.data?.Oid!, file);

    parameter
      .setPrivate(this.viewModel.privateFile);

    return this.smartDeliveryConfigurationController
      .addRowFile(parameter)
      .toPromise()
      .then((response: CtWebapiGenericResponse<SmartDeliveryConfigurationRowFile>) => {

        this.data?.Files?.push(response.Result);
        this.snackbar.open("File caricato correttamente!");

      })
      .catch(error => {

        console.error("File upload failed", error);
        // Gestisci eventuali errori qui, se necessario
        return Promise.reject(error);

      });

  }

  async submit() {

    if (this.fileUploader?.configuration?.control?.value) await this.sendFile();

    this.data!.ActivationConditions = (this.activationConditions ?? []).filter(obj => obj.Field !== null && obj.Field.trim() !== "");

    this.dialogRef
      .close(this.data);

  }

  protected readonly SmartDeliveryActivationCondition2LabelMapping = SmartDeliveryActivationCondition2LabelMapping;

}
