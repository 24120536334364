import { Component } from "@angular/core";
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { JwtService } from "src/app/core/lib/jwt.service";

function nonNumericValidator(control: AbstractControl): { [key: string]: any } | null {
  const value = control.value;

  if (value && /^\d+$/.test(value.replace(/\s/g, ''))) {
    return { nonNumeric: true }; // Il campo contiene solo numeri
  }

  return null; // Il campo è valido
}



@Component({
  selector: "app-letter-additions",
  templateUrl: "./letter-additions.component.html",
  styleUrls: ["./letter-additions.component.scss"]
})
export class LetterAdditionsComponent {
  form: FormGroup;
  editCostCenterEnabled : boolean | null = null;

  constructor(private _fb: FormBuilder, private _jwtService: JwtService) {
    this.form = this._fb.group({
      name: new FormControl("", [Validators.required, nonNumericValidator])
    });

  }

  async ngOnInit() {

    this.editCostCenterEnabled = await this._jwtService.editCostCenterEnabled();
    const grouCode = await this._jwtService.getGroupCode();
    if(grouCode)
    {
      
      this.form.addControl("CostCenter", new FormControl(grouCode, [Validators.required, Validators.maxLength(30)]));

    }
    
  }
}
