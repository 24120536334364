<div
  class="alert-multiple-recipients-invalid-cntr">

  <h1 mat-dialog-title>Attenzione</h1>
  <div mat-dialog-content>

    <ng-container *ngIf="this.validationResult.length == 20 ; else noMaxErrorsTmpl">
      
      <p>
        Il file che hai caricato contiene degli errori. Verranno visualizzati i primi {{this.validationResult.length}} di questi. <br />
        Ti preghiamo di correggerli e di caricare nuovamente il file.
      </p>

    </ng-container>

    <ng-template #noMaxErrorsTmpl>
      
      <p *ngIf="this.validationResult.length > 0">
        Il file che hai caricato contiene degli errori. Sono visualizzati gli {{this.validationResult.length}} errori riscontrati. <br />
        Ti preghiamo di correggerli e di caricare nuovamente il file.
      </p>

    </ng-template>

    <mat-list>

      <ng-container *ngIf="genericError; else errorList">

        <mat-list-item>
          <mat-icon mat-list-icon>report</mat-icon>
          <div mat-line>{{ data.FileErrors[0] }}</div>
        </mat-list-item>

      </ng-container>

      <ng-template #errorList>

        <ng-container *ngFor="let key of fileErrorsKeys">

          <div mat-subheader> Riga {{ key }}</div>
          <mat-list-item *ngFor="let error of data.FileErrors[key]">
            <mat-icon mat-list-icon>report</mat-icon>
            <div mat-line>{{ error }}</div>
          </mat-list-item>

          <mat-divider></mat-divider>

        </ng-container>

      </ng-template>

    </mat-list>

    <mat-accordion *ngIf="validationResult.length">

      <mat-expansion-panel *ngFor="let validation of validationResult">

        <mat-expansion-panel-header>
          <mat-panel-title>
            Il sistema ha corretto l'indirizzo {{ _validator.getPrintedOption(validation) }}
          </mat-panel-title>
        </mat-expansion-panel-header>

        <p>Correzioni disponibili:</p>
        <mat-list>

          <ng-container *ngFor="let item of validation.ValidationResult?.SuggestedCorrections">

            <mat-list-item>
              {{ _validator.getPrintedOption(item) }}
            </mat-list-item>

            <mat-divider></mat-divider>

          </ng-container>

        </mat-list>
      </mat-expansion-panel>

    </mat-accordion>

  </div>
  <div
    mat-dialog-actions>

    <button mat-button mat-dialog-close cdkFocusInitial color="warn">Chiudi</button>

    <button
      *ngIf="viewModel.autoCorrectRecipientFileEnabled"
      mat-button
      color="primary"
      [mat-dialog-close]="true">
      Correzione automatica
    </button>

  </div>

</div>
