import { Component, Input, NgZone } from "@angular/core";
import { environment } from "../../../../environments/environment";
import { FlowComponentCardContainerConfiguration } from "../flow-component-card-container.configuration";
import { FormControl, ValidatorFn } from "@angular/forms";
import { FlowStepsController } from "../../../core/controllers/flow-steps.controller";
import { FileService } from "../../../core/lib/file.service";
import { MatLegacyDialog } from "@angular/material/legacy-dialog";
import { TemplatesManagerComponent } from "./templates-manager/templates-manager.component";
import { TemplateManagerConfiguration } from "./template-manager.configuration";
import { SendTypeSendCategoryEnum } from "../../../core/interfaces/send-type-configuration";
import { TinyMceSetupConfiguration } from "./tiny-mce.setup-configuration";
import { TemplateSaveService } from "./template-save/template-save.service";
import { HtmlEditorComponent } from "./html-editor/html-editor.component";
import { HtmlWarningModalComponent } from "./html-editor/html-warning-modal.component";
import { distinctUntilChanged } from "rxjs/operators";
import { debounceTime, startWith } from "rxjs";
import { TemplateLiteResponse } from "../../../core/interfaces/template-lite-response";
import { TemplateParameter } from "../../../core/classes/template-parameter";

const conditionalCommentTypes = [
  "if mso",
  "if gte mso 9",
  "if !mso",
  "if supportFields",
  "if !vml",
  "if !ppt",
  "if gte vml 1"
  // altri tipi di commenti condizionali rilevanti
];

const customPatterns = [
  /<[^\s>]*\bmso\b[^>]*>/gi //caratteri word
  // altre espressioni regolari per i tipi di caratteri che vuoi rilevare
];

@Component({
  selector: "app-editor",
  templateUrl: "./tiny-mce-editor.component.html",
  styleUrls: ["./tiny-mce-editor.component.scss"],
  providers: [FlowStepsController]
})
export class TinyMceEditorComponent {

  @Input() configuration!: FlowComponentCardContainerConfiguration;
  control: FormControl = new FormControl(null);

  PPTinyMCEApiKey: string | null = environment.keys.TinyMCE;

  editorConfiguration: any = null;
  tempTemplateRef: TemplateLiteResponse | null = null;

  private hasShownWarning = false;

  constructor(
    private flowSteps: FlowStepsController,
    private fileHelper: FileService,
    private dialog: MatLegacyDialog,
    private templateSaveService: TemplateSaveService,
    private ngZone: NgZone) {

    this.control
      .valueChanges
      .pipe(
        startWith(""),
        debounceTime(200),
        distinctUntilChanged())
      .subscribe(value => this.checkConditionalComments(value));

  }

  checkConditionalComments(value: string | null) {

    if (!value || this.hasShownWarning) return;

    const lowercasedValue = value.toLowerCase();

    const foundTypes = conditionalCommentTypes.filter(type => lowercasedValue.includes(`<!--[${type.toLowerCase()}]>`));

    const customMatches = customPatterns.some(pattern => pattern.test(lowercasedValue));

    if (foundTypes.length > 0 || customMatches) {

      this.hasShownWarning = true;
      this.dialog.open(HtmlWarningModalComponent, { maxWidth: "500px" });

    }

  }

  setup(configuration: TinyMceSetupConfiguration) {

    this.editorConfiguration = null;

    setTimeout(() => {

      this.editorConfiguration = {

        selector: "#myEditor",
        menubar: false, //  "file edit insert view format table tools", PREMIUM
        branding: false,
        language_url: "/assets/i18n/langs/it.js",  // site absolute URL
        language: "it",
        plugins: "pagebreak table fullpage",
        pagebreak_separator: "<p style='page-break-after: always'></p>",
        toolbar1: "undo redo copy  | styles fontfamily fontsizeinput forecolor | bold italic underline | alignleft aligncenter alignright alignjustify |  table | pagebreak",
        toolbar2: "polipush_templates | html_content | dynamic_fields_button",
        content_style: `.mce-content-body {font-family:"Times New Roman", Times, serif;} .mce-content-body table:not([cellpadding]) td, .mce-content-body table:not([cellpadding]) th {padding: 0 !important;}`,
        height: 530,
        toolbar_mode: "floating", // Disabilita gli ellipsis e permetti agli elementi di andare a capo
        setup: (editor: any) => this.setupEditor(editor, configuration),
        force_br_newlines: false,
        force_p_newlines: false,
        apply_source_formatting: false,
        verify_html: false,
        valid_elements: "*[*]",
        cleanup: false,
        cleanup_on_startup: false,
        entity_encoding: "raw",
        allow_conditional_comments: true,
        convert_urls: false,
        protect: [
          /<!--\[if \(!mso\)&\(!IE\)\]>/g, // Protect <!--[if (!mso)&(!IE)]>
          /<!-->/g                        // Protect <!-->
        ]
      };

    }, 0);


    if (configuration.initialValue) {

      this.setValue(configuration.initialValue);

    }

    if (configuration.validators) {

      this.setControlValidators(configuration.validators);

    }

  }

  private async setupEditor(editor: any, configuration: TinyMceSetupConfiguration) {

    // serve per gestire i caratteri di tabulazione, però genera problemi UX, se per esempio voglio cambiare cella in una tabella, con questa funzione l'editor non mi consente più di farlo
    // editor.on("keydown", function(event: any) {
    //   if (event.key === "Tab") {
    //     // Inserisci un carattere di tabulazione nell'editor
    //     editor.execCommand("mceInsertContent", false, "&emsp;&emsp;");
    //     event.preventDefault();
    //     return false;
    //   }
    // });

    if (configuration?.sendTypeSendCategoryEnum && configuration?.templateManagementEnabled) {

      editor.ui.registry.addMenuButton("polipush_templates", {
        text: "Modelli",
        icon: "template",
        fetch: (callback: any) => callback([
          {
            type: "menuitem",
            text: "I tuoi modelli",
            onAction: () => this.ngZone.run(() => this.templatesList(configuration?.sendTypeSendCategoryEnum!))
          },
          {
            type: "menuitem",
            text: "Salva modello",
            onAction: () => this.ngZone.run(() => this.saveAsTemplate(configuration?.sendTypeSendCategoryEnum!))
          }
        ])

      });

    }

    editor.ui.registry.addButton("html_content", {
      text: "HTML",
      onAction: () => this.ngZone.run(() => this.htmlEditor()),
      icon: "code-sample"
    });

    if (configuration?.templateManagementEnabled) {

      editor.ui.registry.addMenuButton("dynamic_fields_button", {
        text: "Campi dinamici",
        icon: "non-breaking",
        fetch: async (callback: any) => {

          const variableFields: string[] = await this.flowSteps.getCompositionVariableFields();

          const mergeTags = variableFields.map((elm: string) => ({
            type: "menuitem",
            text: ` ${elm} `,
            onAction: () => editor.insertContent(`{{{${elm}}}}`)
          }));

          callback(mergeTags);
        }
      });

    }

  }

  setControlValidators(validators: ValidatorFn | ValidatorFn[] | null) {

    this.control.setValidators(validators);
    this.control.updateValueAndValidity();

  }

  private setValue(value: string | null) {

    this.control.setValue(value);
    this.control.updateValueAndValidity();

  }

  private htmlEditor() {

    this.dialog
      .open(HtmlEditorComponent, { width: "90vw", maxWidth: "900px", data: this.control.value })
      .afterClosed()
      .subscribe(result => {

        if (result) {

          this.control.setValue(result);

        }

      });

  }

  private async saveAsTemplate(sendTypeSendCategoryEnum: SendTypeSendCategoryEnum) {

    this.templateSaveService.saveAsTemplate(this.control, this.tempTemplateRef, sendTypeSendCategoryEnum);

  }

  private templatesList(sendTypeSendCategoryEnum: SendTypeSendCategoryEnum) {

    const parameter: TemplateManagerConfiguration = TemplateManagerConfiguration
      .create()
      .setParameter(
        TemplateParameter
          .create()
          .setSendCategory(sendTypeSendCategoryEnum)
      );

    this.dialog
      .open(TemplatesManagerComponent, { data: parameter, width: "90vw", maxWidth: "900px" })
      .beforeClosed()
      .subscribe((result: { content: string | null, data: TemplateLiteResponse | null }) => {

        if (result) {

          this.control.setValue(result.content);
          this.tempTemplateRef = result.data;

        }

      });

  }

  setupContentFromPPFile(configuration: TinyMceSetupConfiguration) {

    this.fileHelper
      .getComponentStringContentFromPPFile(configuration)
      ?.then(result => this.setValue(<string>result ?? ""));

  }

}
