<form class="m-b-15" [formGroup]="form" fxLayout="row wrap">
  <div fxFlex.gt-sm="100" fxFlex.gt-md="100" fxFlex="100" class="p-10">
    <mat-form-field>
      <input matInput placeholder="Descrizione ordine" formControlName="name" />
      <mat-error *ngIf="form.get('name')?.hasError('required')"> * Campo obbligatorio</mat-error>
      <mat-error *ngIf="form.get('name')?.hasError('nonNumeric') && !form.get('name')?.hasError('required')"> * La
        descrizione dell'ordine non può contenere solo valori numerici. Inserisci una descrizione alfanumerica per
        garantire l'integrità dei dati.
      </mat-error>

    </mat-form-field>
  </div>

  <div fxFlex.gt-sm="100" fxFlex.gt-md="100" fxFlex="100" class="p-10" *ngIf="editCostCenterEnabled">
    <mat-form-field>
      <input matInput placeholder="Centro di Costo" formControlName="CostCenter" />
      <mat-error *ngIf="form.get('CostCenter')?.hasError('required')"> * Campo obbligatorio</mat-error>
      <mat-error *ngIf="form.get('CostCenter')?.hasError('maxlength') && !form.get('CostCenter')?.hasError('required')"> * Il Centro 
        di Costo non può superare i 30 caratteri.
      </mat-error>
    </mat-form-field>
  </div>
</form>
